import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from 'docz';
import { useDarkMode } from './';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "usedarkmode"
    }}>{`useDarkMode`}</h1>
    <p>{`Use this hook to control the user dark mode preference. By default it will rely
only on the users settings via css media queries. But it is also possible to
override and control this behaviour.`}</p>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React from 'react';
import { useDarkMode } from '@fransvilhelm/hooks';

const Example = () => {
  const [enabled, setEnabled, isUserOverridden] = useDarkMode();
  return <p />;
};
`}</code></pre>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <Playground __position={0} __code={'() => {\n  const [enabled, setEnabled, isUserOverridden] = useDarkMode()\n  return (\n    <div\n      style={{\n        padding: \'1rem\',\n        backgroundColor: enabled ? \'black\' : \'white\',\n        color: enabled ? \'white\' : \'black\',\n      }}\n    >\n      <form style={{ display: \'flex\', flexFlow: \'column nowrap\' }}>\n        <label>\n          <input\n            type=\"radio\"\n            name=\"dark-mode\"\n            value=\"user\"\n            checked={!isUserOverridden}\n            onChange={() => setEnabled(null)}\n          />{\' \'}\n          Respect user settings\n        </label>\n        <label>\n          <input\n            type=\"radio\"\n            name=\"dark-mode\"\n            value=\"enabled\"\n            checked={isUserOverridden && enabled}\n            onChange={() => setEnabled(true)}\n          />{\' \'}\n          Use dark mode\n        </label>\n        <label>\n          <input\n            type=\"radio\"\n            name=\"dark-mode\"\n            value=\"disabled\"\n            checked={isUserOverridden && !enabled}\n            onChange={() => setEnabled(false)}\n          />{\' \'}\n          Use light mode\n        </label>\n      </form>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      useDarkMode,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [enabled, setEnabled, isUserOverridden] = useDarkMode();
        return <div style={{
          padding: '1rem',
          backgroundColor: enabled ? 'black' : 'white',
          color: enabled ? 'white' : 'black'
        }}>
        <form style={{
            display: 'flex',
            flexFlow: 'column nowrap'
          }}>
          <label>
            <input type="radio" name="dark-mode" value="user" checked={!isUserOverridden} onChange={() => setEnabled(null)} />{' '}
            Respect user settings
          </label>
          <label>
            <input type="radio" name="dark-mode" value="enabled" checked={isUserOverridden && enabled} onChange={() => setEnabled(true)} />{' '}
            Use dark mode
          </label>
          <label>
            <input type="radio" name="dark-mode" value="disabled" checked={isUserOverridden && !enabled} onChange={() => setEnabled(false)} />{' '}
            Use light mode
          </label>
        </form>
      </div>;
      }}
    </Playground>
    <h2 {...{
      "id": "parameters"
    }}>{`Parameters`}</h2>
    <p><inlineCode parentName="p">{`useDarkMode`}</inlineCode>{` accepts ...`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Param`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`className`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Optional class name that will be appended to the body element`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "returns"
    }}>{`Returns`}</h2>
    <p><inlineCode parentName="p">{`useDarkMode`}</inlineCode>{` returns a tuple with three items:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`enabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Indicating if dark mode should be used or not`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`setEnabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`(enable: boolean `}</inlineCode>{`|`}<inlineCode parentName="td">{` null) => void`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Function to override dark mode preference. Call with `}<inlineCode parentName="td">{`null`}</inlineCode>{` to revert back on relying on users OS settings`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`isUserOverriden`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Flag indicating if user has overridden system settings`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      